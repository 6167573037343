<template>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
  
        <!-- Register v1 -->
        <b-card class="mb-0">
            <b-row>
                <b-col sm="12" class="text-center">
          <b-img
            fluid
            class="brand-logo"
            style="max-width:100%;"
            :src="client.logo"
           
          />
        </b-col>
       
    </b-row>
          <b-card-title class="mb-1">
            登記會員 Register Member
          </b-card-title>
          <b-card-text class="mb-2">
            <div v-if="insert === true" v-html="message"></div>
          </b-card-text>
          
          <!-- form -->
          <validation-observer ref="registerForm">
            <h4 class="brand-text text-primary ml-1" v-if="valid === false">Invalid Link</h4>
            <b-form v-if="insert === false && valid === true">
          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Chinese Name"
          >
            <b-form-group
              label="中文姓名 Chinese Name"
              label-for="Chinese Name"
            >
              <b-form-input
                id="Chinese Name"
                v-model="userData.zh_name"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="en_name"
            rules="required"
          >
            <b-form-group
              label="英文姓名 English Name"
              label-for="en_name"
            >
              <b-form-input
                id="en_name"
                v-model="userData.en_name"
                class="text-uppercase"
                
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>







          <validation-provider
            #default="validationContext"
            name="email"
          >
            <b-form-group
              label="電子郵件 E-Mail"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="identity"
          >
            <b-form-group
              label="身份證明文件號碼 Identity Number"
              label-for="identity"
            >
              <b-form-input
                id="identity"
                v-model="userData.identity"
                
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


            <validation-provider
            #default="validationContext"
            name="mobile"
             rules="required"
          >
            <b-form-group
              label="手提電話 Mobile"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                v-model="userData.mobile"
                
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="address"
          >
            <b-form-group
              label="聯絡地址 Address"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="userData.address"
                
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

<validation-provider
              #default="validationContext"
              name="Birth"

            >

              <b-form-group
                label="出生日期 Birth"
                label-for="birth"
              >
              <b-form-input
                id="birth"
                v-model="userData.birth"
                v-mask="'####-##-##'"
                placeholder="YYYY-MM-DD"
                trim
              />
                <b-form-invalid-feedback >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          <validation-provider
            #default="validationContext"
            name="性別 Gender"
            rules="required"
          >
            <b-form-group
              label="性別 Gender"
              label-for="gender"
              
            >
              <v-select
                v-model="userData.gender"
                :options="['Male 男','Female 女']"
                :clearable="false"
                input-id="gender"
              />
              <b-form-invalid-feedback >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
              <!-- checkbox -->
              <!--<b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>
  
              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="button"
                @click="submit"
              >
                送出 Submit
              </b-button>
            </b-form>
          </validation-observer>
  
  <div class="text-center mt-3"><b>Power by CloudFit</b></div>
  
  
        </b-card>
      <!-- /Register v1 -->
      </div>
    </div>
  
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
    BCard, BLink, BCardTitle, BCardText, BForm,BImg,BRow,BCol,
    BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,BFormInvalidFeedback
  } from 'bootstrap-vue'
  import router from '@/router'
  import flatPickr from 'vue-flatpickr-component'

  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import vSelect from 'vue-select'

  export default {
    components: {
      VuexyLogo,
      // BSV
      BCard,
      vSelect,
      BRow,BCol,
      BLink,
      BImg,
      BCardTitle,
      router,
      BCardText,
      BFormInvalidFeedback,
      BForm,
      flatPickr,
      BButton,
      BFormInput,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        regEmail: '',
        username: '',
        message: '',
        valid : true,
        password: '',
        userData : {
            zh_name : null,
            en_name : null,
            email : null,
            hkid : null,
            mobile : null,
            address : null,
            birth : null,
            gender : null,
        },
        status: '',
        insert: false,
        credit_card_format: '#### #### #### ####',
        cvc_format:'###',
        client : {
            client_name : null,
            web_key : null,
            logo : null
        },
        
        // validation rules
        required,
        email,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    created() {
      this.get_center()
    },
    methods: {
        get_center() {
        this.$http.get(process.env.VUE_APP_API_BASE+`/client/`+ router.currentRoute.params.center_code + `/`)
          .then(res => {
            if (res.data.result === false ) {
              this.valid = false
            } else {
              this.client = res.data.client
            }
          })
      },
      submit() {
        this.$http.post(process.env.VUE_APP_API_BASE+'/qr/register/'+ router.currentRoute.params.center_code +'/', this.userData)
          .then(res => {
            if (res.data.result === true) {
              this.insert = true
            //   this.$swal({
            //     title: 'Success!',
            //     text: '你成功註冊!',
            //     icon: 'success',
            //     showConfirmButton : false,
            //     buttonsStyling: false,
            //     backdrop: false,
            //   })
            this.message = res.data.message
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: res.data.message,
                },
              })
            }
          })
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  